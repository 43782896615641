import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import Card from "../components/Card"

const AgendaPage = function ({
  data: { agendaJson, allAgendaItemsJson },
  location,
}) {
  return (
    <Layout>
      <Seo
        title={agendaJson.title}
        description={agendaJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={agendaJson.banner.background}
        content={agendaJson.banner.content}
      />
      <div className="container">
        <section>
          <h2>{agendaJson.title}</h2>
          <ul className="grid md:grid-cols-3 md:gap-5">
            {allAgendaItemsJson.nodes.map(function (item) {
              return <Card key={uuidv4()} item={item} />
            })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

AgendaPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AgendaPage

export const query = graphql`
  query AgendaPageQuery {
    agendaJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
    }
    allAgendaItemsJson {
      nodes {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        content
      }
    }
  }
`
